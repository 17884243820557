import React, { useState } from "react";
import PopSlideCarouse from "../components/pop-slide-carousel";
import classNames from "classnames";

const Loader = () => <div className="loader" style={{ position: "absolute" }} />;

const VideoSlide = ({ slide }) => {
  const [contentLoaded, setContentLoaded] = useState(false);

  return (
    <div className="flex justify-center items-center">
      {!contentLoaded && <Loader />}
      <video
        autoPlay
        loop
        muted
        preload={"auto"}
        onLoadedData={() => {
          setContentLoaded(true);
        }}
        className="mw9 vh-90"
        style={{ maxWidth: "90vw" }}
      >
        <source type="video/mp4" src={slide.src} />
      </video>
    </div>
  );
};

const ImageSlide = ({ slide }) => (
  <div className="flex flex-column">
    <img className="vw-75 vh-75" src={slide.src} alt={slide.title || "Image related to project"} />
  </div>
);

const MatchSlideTypeToComponent = ({ slide }) => {
  const { type } = slide;
  let Slide;
  if (type === "video") Slide = <VideoSlide slide={slide} />;
  if (type === "image") Slide = <ImageSlide slide={slide} />;
  return <div className="slide w-100 flex justify-center items-center pa3">{Slide}</div>;
};

export default ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <div>
      <PopSlideCarouse slideIndex={slideIndex} className="w-100 bg-near-white vh-90" onSlideChange={setSlideIndex}>
        {slides.map((slide, idx) => (
          <MatchSlideTypeToComponent key={idx} slide={slide} />
        ))}
      </PopSlideCarouse>
      {slides.length > 1 && (
        <div className="bg-near-white h2 flex justify-center items-center">
          {slides.map((slide, idx) => (
            <div
              className={classNames("h1 w1 br-100 mh1 pointer", {
                "bg-white": slideIndex !== idx,
                "bg-orange": slideIndex === idx
              })}
              onClick={() => setSlideIndex(idx)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
