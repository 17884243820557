import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import Slider from "../components/slider";
import { PROJECTS } from "../data/data.js";

const renderers = {
  root: ({ children }) => <div className="dark-gray">{children}</div>,
  heading: ({ level, children }) => React.createElement(`h${level}`, { className: `f3 fw6 dark-gray mb0` }, children),
  paragraph: ({ children }) => <p className="lh-copy f4 dark-gray mt0">{children}</p>,
  strong: ({ children }) => <span className="fw6">{children}</span>,
  link: ({ children, href }) => (
    <a className="link dim orange" href={href} target="_about">
      {children}
    </a>
  ),
  list: ({ children }) => <ul className="lh-copy f5 dark-gray mt3">{children}</ul>
};

const Meta = ({ project }) => (
  <div className="w-50-l">
    <h2 className="f2 dark-gray mb2 mt4">{project.title}</h2>
    <p className="f5 ttu tracked fw4 gray mt1 mb0">@{project.host}</p>
    <h3 className="f5 tracked fw3 gray mt4 mb4">{project.tags.map(tag => `#${tag} `)}</h3>
    {project.urls.map((urlData, idx) => (
      <div className="mb2">
        <a className="f5 tracked link hot-pink dim" target="_blank" href={urlData.url}>
          {urlData.name}
        </a>
      </div>
    ))}
  </div>
);

export default () => {
  const { id } = useParams();

  const [description, setDescription] = useState("");
  const project = PROJECTS.find(p => p.id === id);
  useEffect(() => {
    if (project) {
      fetch(`/projects/${project.id}/data.md`)
        .then(resp => resp.text())
        .then(md => setDescription(md));
    }
  }, []);
  return (
    <div className="page">
      <Link
        to="/"
        className="fixed z-999 top-1 left-1 w3 h3 bg-near-white f2 lh-copy dark-gray flex items-center justify-center link dim ba b--near-white"
      >
        ‹
      </Link>
      <section className="center mb5">
        {project ? <Slider slides={project.slides} /> : null}
        <div className="mw8 flex-l pa3 pa0-l center">
          {project ? <Meta project={project} /> : "This Project will be here soon"}
          <div className="w-50-l mt4">
            {project ? <ReactMarkdown source={description} renderers={renderers} /> : null}
          </div>
        </div>
      </section>
    </div>
  );
};
