export const PROJECTS = [
  {
    id: "carepredict-at-home",
    thumb: "thumb.jpg",
    title: "CarePredict @Home App",
    host: "flow-control",
    tags: ["ui", "ux", "mobile-app", "iot"],
    urls: [{ name: "Project Site", url: "https://www.carepredict.com/" }],
    slides: [
      { type: "video", src: "/projects/carepredict-at-home/slides/1.mp4" },
      { type: "image", src: "/projects/carepredict-at-home/slides/2.svg", title: "Charts design process" }
    ]
  },
  {
    id: "esa",
    thumb: "thumb.png",
    title: "European Space Agency Dashboard",
    host: "flow-control",
    tags: ["ui", "ux", "code-sketching", "web-app"],
    urls: [],
    slides: [
      { type: "video", src: "/projects/esa/slides/1.mp4" },
      { type: "video", src: "/projects/esa/slides/2.mp4" }
    ]
  },
  {
    id: "moleculeone",
    title: "MoleculeOne",
    host: "flow-control",
    tags: ["ux", "research", "code-sketching", "data-analyze"],
    urls: [{ name: "Project Site", url: "https://molecule.one" }],
    thumb: "thumb.png",
    slides: [
      { type: "video", src: "/projects/moleculeone/slides/1.mp4" },
      { type: "image", src: "/projects/moleculeone/slides/2.png", title: "Design Process" },
      { type: "image", src: "/projects/moleculeone/slides/3.png", title: "Design Process" }
    ]
  },
  {
    id: "fibaro",
    title: "Fibaro Smart Home",
    host: "flow-control",
    tags: ["ux", "web-app", "iot", "smart-home"],
    urls: [],
    thumb: "thumb.png",
    slides: [{ type: "video", src: "/projects/fibaro/slides/1.mp4" }]
  },
  {
    id: "hot",
    title: "HOT Visualize Tool",
    host: "flow-control",
    tags: ["ui", "ux", "web-app", "mapbox"],
    urls: [
      { name: "Project Site", url: "https://www.hotosm.org/updates/launching-visualize-change/" },
      { name: "GitHub Repo", url: "https://github.com/hotosm/visualize-change" }
    ],
    thumb: "thumb.jpg",
    slides: [
      { type: "image", src: "/projects/hot/slides/2.gif" },
      { type: "image", src: "/projects/hot/slides/3.gif" },
      { type: "video", src: "/projects/hot/slides/1.mp4" }
    ]
  },
  {
    id: "nasaa",
    title: "National Assembly of State Arts Agencies",
    host: "flow-control",
    tags: ["ui", "ux", "raport", "data-vis"],
    urls: [
      { name: "Project Site", url: "https://nasaa-arts.org/communication/the-value-of-your-states-creative-economy/" }
    ],
    thumb: "thumb.png",
    slides: [
      { type: "video", src: "/projects/nasaa/slides/1.mp4" }
    ]
  },
  {
    id: "carepredict-replay-map",
    title: "Carepredict Replay Map",
    host: "flow-control",
    tags: ["ui", "ux", "raport", "data-vis"],
    urls: [],
    thumb: "thumb.png",
    slides: [
      { type: "video", src: "/projects/carepredict-replay-map/slides/1.mp4" }
    ]
  }
];
