import React from "react";
import { Route, withRouter } from "react-router-dom";
import { AnimatedSwitch } from "./components/animated-switch";
import HomePage from "./pages/home";
import ProjectPage from "./pages/project";

const routes = [
  {
    component: HomePage,
    path: "/"
  },
  {
    component: ProjectPage,
    path: "/p/:id"
  }
];

const Routes = withRouter(({ location }) => {
  return (
    <AnimatedSwitch location={location}>
      {routes.map(route => {
        return <Route exact key={route.path} path={route.path} component={route.component} />;
      })}
    </AnimatedSwitch>
  );
});

export default Routes;
