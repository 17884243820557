import React from "react";
import { Link } from "react-router-dom";
import { PROJECTS } from "../data/data.js";

export default () => {
  return (
    <div className="page">
      <div className="bg-white">
        <div className="center flex-l near-black items-center">
          <img src="/avatar.jpeg" className="w-30-l" alt="avatar" />

          <div className="pa4 dark-gray">
            <h1 className="mt0 f1">Hi, </h1>
            <p className="lh-copy f4">
              my name is Jan Hoffmann and I love solving problems.
              <br />
              I'm strongly interested in holistic approach in software development, believing that understanding both
              design and tech side creates wider perspective.
              <br /> I'm always excited of trying new things and tackle complex problems to deliver clear and engaging
              experience for the end user.
            </p>
          </div>
        </div>
      </div>

      <div className="mw8 center mt5">
        <div className="pa2 mt3">
          <h2 className="f2 lh-title mb4 dark-gray">Projects</h2>
          <hr className="bb bw1 bg-black b--black" />
          <div className="flex-l flex-wrap">
            {PROJECTS.map((project, idx) => {
              const paddingClass = idx % 2 !== 0 ? "pl4-l" : "pr4-l";
              return (
                <Link
                  key={idx}
                  to={`/p/${project.id.toLowerCase()}`}
                  className={`flex flex-column pa2 pa0-l mt4 pv4 w-50-l pa0 ${paddingClass} project-item link dark-gray`}
                >
                  <div className="w-100">
                    <img className="w-100" src={`/projects/${project.id}/${project.thumb}`} />
                  </div>
                  <div className="">
                    <h2 className="f3 lh-title mb0 mt1">{project.title}</h2>
                    <p className="f5 fw3 mt1">@{project.host}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
